<template>
  <main>
    <div class="wrap">
      <div class="h1">
        <h1>GOLD ARCHIVE LINE</h1>
        <hr />
        <span></span>
      </div>
      <div class="content">
        <p>
          Архивный CD-R Recassist Gold Archive Line был специально разработан для долгосрочного архивирования, 
          для защиты и хранения всех данных, записей, книг и коллекций, гарантируя их наследие и доступность 
          для будущих поколений. Архивный CD-R Recassist Gold Archive Line имеет двойной отражающий слой из золота и 
          серебра для обеспечения максимальной совместимости. Слой золота обеспечивает максимальную устойчивость 
          к ухудшению состояния окружающей среды. Особенно коррозия, которая является одной из основных причин 
          выхода дисков из строя в стандартных средах. Покаслой серебра обеспечивает высокую отражательную 
          способность и широкую совместимость с приводом. Архивный CD-R Gold Archive Line прошел испытания 
          на ускоренное старение, выполненные в условиях высокой влажности и высоких температур, что подтверждает 
          отличный срок службы архива. Gold Archive Line CD-R гарантирует безопасное архивное хранение более 300 лет. 
          Использование слоя серебра с высокой отражающей способностью и стандартной краски для записи премиум-класса 
          обеспечивает наилучшую совместимость накопителя во время записи и воспроизведения.
        </p>
        <p>
          Архивная линейка DVD-R Recassist Gold Archive Line EP 8x 4,7 ГБ оснащена двойным отражающим / защитным слоем 
          из золота и серебра. Слой золота обеспечивает максимальную устойчивость к ухудшению состояния окружающей среды, 
          особенно коррозии, которая является одной из основных причин выхода дисков из строя в стандартных средах, 
          в то время как слой серебра обеспечивает высокую отражательную способность и широкую совместимость с приводом. 
          Архивная линейка DVD-R Gold EP была протестирована в условиях ускоренного старения при жестких 
          условиях хранения при высокой температуре и высокой влажности в соответствии со стандартами ISO. Результаты 
          подтверждают безопасное хранение данных в течение более чем 100 лет. Архивная линейка DVD-R Gold EP 
          соответствует требованиям ROHS/REACH и DICOM. Со ссылкой на отчет о тестировании LNE (Национальная лаборатория
           метрологии и медицины - Франция) этот продукт прошел сертификацию ISO/IEC 16963 (ECMA 396). 
           Стандартные испытания и условия.<br/><br/>
           Доступность поверхностей для печати:<br/>
           • Inkjet Smart White, Smart Guard.<br/>
           • Thermal White.<br/>
           • Другое: Пустой Блестящий серебряный лак и шелкография по индивидуальному заказу.<br/><br/>
           Основные преимущества:<br/>
           • Идентификационный код носителя TDK (MID).<br/>
           • Гарантированный срок службы архива 100 лет.<br/>
           • Диск с золотым слоем и архивный сорт.<br/>
           • Высокая производительность при низкой частоте ошибок.<br/>
           • Совместимость с самыми популярными накопителями.<br/>
           • Упаковка в черную коробку для защиты от ультрафиолетового излучения и более длительного срока службы.
        </p>
      </div>
      <div class="disc-structure">
        <img src="/images/CENTURY-LINE-CD-R.jpg" alt="">
        <img src="/images/CENTURY-LINE-DVD-R.jpg" alt="">
      </div>
      <div class="data-sheet">
        <h2>СПЕЦИФИКАЦИЯ</h2>
        <table>
          <tbody>
            <tr>
              <th>DATA STORAGE SOLUTIONS FOR BUSINESS</th>
              <td>CD-r</td>
              <td>DVD "-" R<br/>
              DVD "+" R<br/>
              DVD DL "-" R</td>
            </tr>
            <tr>
              <th><b>DISC INFORMATION</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Speed</th>
              <td>52x</td>
              <td>8x</td>
            </tr>
            <tr>
              <th>Capacity, MB</th>
              <td>700</td>
              <td>4700</td>
            </tr>
            <tr>
              <th>Media Code</th>
              <td>TDK</td>
              <td>TDK</td>
            </tr>
            <tr>
              <th>Time Code</th>
              <td>97m15s05f - 79m59s74f</td>
              <td>TDK TTH02 </td>
            </tr>
            <tr>
              <th>Safe Storage (Years)</th>
              <td>300</td>
              <td>300</td>
            </tr>
            <tr>
              <th>Estimated shelf life under office condition</th>
              <td>100 Years, ECMA 396, ECMA 379</td>
              <td>100 Years, ECMA 396, ECMA 379</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Phtalocianine Light Green Dye</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>CERTIFICATIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Archival Line Certifitation</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>LNE Certification</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>ROHS / REACH Compliant</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>CE Sertification</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>DICOM Compliant</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th>ECMA 366 & 379 Compliance</th>
              <td></td>
              <td>+</td>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th><b>PRINT OPTIONS</b></th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Smart Guard</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Smart White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Water Repelent</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Inkjet White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Inkjet Silver Pearl</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Smart Glossy</th>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Thermal White</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Thermal Silver</th>
              <td>+</td>
              <td>+</td>
            </tr>
            <tr>
              <th>Custom Printing</th>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
$gal: #a56b00;
.disc-structure {
  text-align: center;
  margin-top: 30px;
}
.data-sheet {
  margin-top: 40px;
  h2 {
    text-align: center;
    font-size: 45px;
  }
  table {
    width: 100%;
    margin-top: 40px;
    
    tr {
    th {
      text-align: left;
      border-bottom: 1px solid grey;
      padding: 3px;  

      b{
        font-weight: bolder;
        font-size: 20px;
        color: $gal;
      }
    }
    
    td {
        width: 300px;
        border-bottom: 1px solid grey;
       border-left: 1px solid grey;
       padding: 3px;
       text-align: center;
      }      
    }
  }
}
// $ml: #52ab62;

main {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}
.wrap {
  // background-color: white;
  max-width: 1280px;
  margin: 0 auto;
  .h1 {    
    h1{
      font-size: 45px;
      color: $gal;
    }
    hr {
      border: 6px solid $gal;
      background-color: $gal;
    }
  }
  .content {
    margin-top: 30px;
    p{
      line-height: 1.5em;
      font-size: 26px;
    }
  }
}
</style>

<script>
export default {
  name: "ProfiLineComponent",
  data() {
    return {
      rrer: "",
    };
  },
  // components: {
  //   ByeWorld
  // },
  methods: {
    iclick() {
      this.rrer = "";
    },
  },
};
</script>


